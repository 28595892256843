import styled from "styled-components"
import { H3, P } from "../design-system"

export const CardContainer = styled.div`
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  height: 100%;
  padding: 16px;
  padding-left: 48px;
  margin-left: -40px;
  margin-top: 0px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: -40px;
    padding-left: 16px;
    padding-top: 48px;
  }
`

export const CardTitle = styled(H3)`
  font-weight: 900;
  margin: 0;
`

export const CardDescription = styled(P)`
  font-size: 20px;
  line-height: normal;
  margin-top: 8px;
`

export const Image = styled.img`
  width: 80px;
  z-index: 1;
  flex-shrink: 0;
`