import { MDXProvider } from "@mdx-js/react"
import * as DesignSystem from "components/design-system"
import Footer from "components/footer"
import Header from "components/header/"
import { initialiseMessenger } from "components/messenger"
import LayoutElements from "components/new-design-system/layout-elements"
import { locale } from "config/locale"
import * as React from "react"
import Helmet from "react-helmet"
import { IntlProvider } from "react-intl"
import Translations from "translations/index"
import { GlobalColors } from "./colors"
import "./index.scss"

// react-intl now uses native translation APIs
// the if-statements below add polyfills in case
// some stuff doesn't exist in the browser
if (!Intl.PluralRules) {
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-pluralrules/polyfill")
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-pluralrules/dist/locale-data/en") // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-relativetimeformat/polyfill")
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en") // Add locale data for de
}

interface LayoutProps {
  alwaysOnHeader?: boolean,
  className?: string
  title: string
}

export default class Layout extends React.Component<LayoutProps> {
  componentDidMount() {
    if (typeof window !== `undefined` && locale !== "zh") {
      initialiseMessenger()
    }
  }

  render() {
    return (
      <IntlProvider locale={locale} messages={Translations[locale]}>
        <MDXProvider
          components={{
            a: DesignSystem.A,
            blockquote: DesignSystem.BlockQuote,
            code: DesignSystem.InlineCode,
            // delete: "",
            // em: "",
            h1: DesignSystem.H1,
            h2: DesignSystem.H2,
            h3: DesignSystem.H3,
            h4: DesignSystem.H4,
            h5: DesignSystem.H5,
            h6: DesignSystem.H6,
            // hr: "",
            img: LayoutElements.img,
            inlineCode: DesignSystem.InlineCode,
            li: DesignSystem.ListItem,
            ol: DesignSystem.OrderedList,
            p: DesignSystem.P,
            pre: DesignSystem.Pre,
            strong: DesignSystem.Strong,
            table: DesignSystem.Table,
            td: DesignSystem.TableColumn,
            th: DesignSystem.TableColumn,
            tr: DesignSystem.TableRow,
            ul: DesignSystem.UnorderedList
          }}>
          <Helmet title={this.props.title} />

          <GlobalColors />

          <Header alwaysOnBackground={this.props.alwaysOnHeader || false} />

          <DesignSystem.Main className={`container-fluid ${ this.props.className }`} style={{ minHeight: "calc(100vh - 226px)" }}>
            {this.props.children}
          </DesignSystem.Main>

          <Footer />
        </MDXProvider>
      </IntlProvider>
    )
  }
}
