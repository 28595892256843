import * as React from "react"
import { FormattedMessage } from "react-intl"
import { Column } from "../new-section"
import { CardContainer, CardDescription, CardTitle, Image } from "./styled"

interface CardProps {
  image: any
  title: string
  description: string
}

export default class Card extends React.Component<CardProps> {
  render() {
    const { title, description, image, children } = this.props

    return (
      <Column className="col-12 col-md-6 space-outer-top-6 d-flex flex-column flex-md-row align-items-center">
        <Image src={image} />

        <CardContainer className="P700-border">
          <CardTitle>
            <FormattedMessage id={title} />
          </CardTitle>

          <CardDescription>
            <FormattedMessage id={description} />
          </CardDescription>

          {children}
        </CardContainer>
      </Column>
    )
  }
}