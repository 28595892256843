import { P500, P700 } from '@stackchat/colors';
import styled, { css } from 'styled-components';

interface StyleProps {
  color?: string;
}

export const Main = styled.main`
  padding-bottom: 64px;
  padding-left: 0;
  padding-right: 0;
`;

export const InlineCode = styled.code`
  background-color: #efefef;
  font-size: 26px;
  line-height: 32px;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: 'ProjectFontMono';
`;

export const H1 = styled.h1`
  margin: 0;
  font-size: 64px;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 24px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}

  @media only screen and (max-width: 575.98px) {
    font-size: 40px;
  }
`;

export const H2 = styled.h2`
  margin: 0;
  font-size: 44px;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 24px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}

  @media only screen and (max-width: 575.98px) {
    font-size: 36px;
  }
`;

export const H3 = styled.h3`
  margin: 0;
  font-size: 32px;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 8px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}

  ${InlineCode} {
    font-size: 24px;
    height: 36px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
  }
`;

export const H4 = styled.h4`
  margin: 0;
  font-size: 20px;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}
`;

export const H5 = styled.h5`
  margin: 0;
  font-size: 20px;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}
`;

export const H6 = styled.h6`
  margin: 0;
  font-size: 1.25rem;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  ${({ color = '#252525' }: StyleProps) => css`
    color: ${color};
  `}
`;

export const P = styled.p`
  margin: 0;
  font-size: 26px;
  line-height: 32px;

  ${({ color = '#111' }: StyleProps) => css`
    color: ${color};
  `}

  @media only screen and (max-width: 767.98px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const BlockQuote = styled.blockquote`
  ${({ color = '#bdbbbb' }: StyleProps) => css`
    margin: 0;
    font-size: 1.5rem;
    padding-left: calc(1.5rem - 8px);
    border-left: 8px solid #e2e2e2;
    color: ${color};

    * {
      font-size: 2rem;
      color: ${color};
      font-style: italic;
      line-height: 1.5;
    }
  `}
`;

export const UnorderedList = styled.ul`
  margin: 0;
  padding-left: 40px;
`;

export const OrderedList = styled.ol`
  margin: 0;
  padding-left: 40px;
`;

export const ListItem = styled.li`
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 16px;
  color: #111;

  @media only screen and (max-width: 767.98px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 0;
  margin-top: 24px;
`;

export const TableRow = styled.tr``;

export const TableHeadColumn = styled.th`
  background-color: #f6f7fc;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
  padding: 8px 16px;
`;

export const TableColumn = styled.td`
  border: 1px solid #fff;
  padding: 8px 16px;
`;

export const Pre = styled.pre`
  margin: 0;
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 4px;
  background-color: #1e1e1e;
  max-height: 350px;

  .token.important {
    font-weight: normal !important;
  }
`;

export const A = styled.a`
  margin: 0;
  color: ${P700};
  border-bottom: 3px solid ${P700};

  &:hover {
    color: ${P700};
    text-decoration: none;
  }
`;

export const Image = styled.img`
  ${({ width = '100%' }: { width?: string }) => css`
    width: ${width};
  `}
  max-width: 100%;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const HomeAnimation = styled.div`
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-height: 95% !important;
  }

  @media only screen and (max-width: 1199.98px) {
    height: 500px;
  }

  @media only screen and (max-width: 991.98px) {
  }

  @media only screen and (max-width: 767.98px) {
  }

  @media only screen and (max-width: 565.98px) {
    svg {
      max-height: 80% !important;
    }
  }
`;

export const DividedSection = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-size: 60% auto;

  ${(props: { image: any; left?: boolean; position?: string }) => css`
    background-image: url("${props.image}");
    padding-left: ${props.left ? '16px' : '96px'};
    padding-right: ${props.left ? '96px' : '16px'};
    background-position: ${props.position ? props.position : 'top 36% left 0%'};
  `}

  @media only screen and (max-width: 575.98px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 24px;
    background-position: top 0% left 0%;
    background-size: 48% auto;
  }
`;

export const SpecialText = styled(P)`
  font-size: 20px;
  font-weight: 500;
  color: ${P500};
`;
