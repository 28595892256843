import { N0, P700 } from "@stackchat/colors"
import Mapbox from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import * as React from "react"
import markerSelected from "../../assets/svg/marker-selected.svg"
import markerUnselected from "../../assets/svg/marker-unselected.svg"
import { MarkerData as Markers } from "./markers"

Mapbox.accessToken = "pk.eyJ1IjoiaWFtZGlmZmVyZW50OTAiLCJhIjoiY2swM2JsMGR3MnFpajNjcDAxZ2lqdmM0YyJ9.fnLLcBl9jfM1hSrSZfZX_Q"

export default class InteractiveMap extends React.Component {
  private currentTarget: string | null = null

  componentDidMount() {
    const map = new Mapbox.Map({
      container: "map",
      style: "mapbox://styles/iamdifferent90/ck03bnudw76zv1cs2yn4b8s2a/draft",
      zoom: 0,
      center: [16, 16],
      scrollZoom: false
    })

    Markers.forEach(marker => {
      const markerContainer = document.createElement("div")
      markerContainer.id = marker.id
      markerContainer.style.cursor = "pointer"
      markerContainer.style.width = "24px"
      markerContainer.style.height = "21.46px"
      markerContainer.style.backgroundImage = `url("${markerUnselected}")`
      markerContainer.style.backgroundPosition = "50% 50%"
      markerContainer.style.backgroundRepeat = "no-repeat"

      markerContainer.addEventListener("click", e => {
        this.unselectSelectedMarker()

        this.currentTarget = (e.target as HTMLElement).id
        markerContainer.style.backgroundImage = `url("${markerSelected}")`
        markerContainer.appendChild(this.getPopup())
      })

      new Mapbox
        .Marker(markerContainer)
        .setLngLat(marker.geometry.coordinates as Mapbox.LngLatLike)
        .addTo(map)
    })
  }

  unselectSelectedMarker = () => {
    if (!this.currentTarget) {
      return
    }

    const currentTarget = document.getElementById(this.currentTarget) as HTMLElement
    currentTarget.style.backgroundImage = `url("${markerUnselected}")`
    currentTarget.childNodes.forEach(child => currentTarget.removeChild(child))
    this.currentTarget = null
  }

  getPopup = (): HTMLElement => {
    const currentMarker = Markers.find(marker => {
      return marker.id === this.currentTarget
    })

    const popup = document.createElement("div")

    if (currentMarker) {
      popup.style.padding = "4px 8px"
      popup.style.borderRadius = "4px"
      popup.style.position = "absolute"
      popup.style.top = "20px"
      popup.style.left = "calc(-50%)"
      popup.style.backgroundColor = P700

      const text = document.createElement("p")
      text.style.color = N0
      text.innerHTML = currentMarker.properties.place_name
      popup.appendChild(text)
    }

    return popup
  }

  render() {
    return <div
      id="map"
      style={{
        width: "100%",
        height: 300
      }}
    />
  }
}