export const MarkerData = [
  {
    "type": "Feature",
    "properties": {
      "short_code": "ie",
      "wikidata": "Q27",
      "place_name": "Ireland"
    },
    "geometry": {
      "coordinates": [
        -8,
        53
      ],
      "type": "Point"
    },
    "id": "country.15797503071111290"
  },
  {
    "type": "Feature",
    "properties": {
      "wikidata": "Q8646",
      "place_name": "Hong Kong"
    },
    "geometry": {
      "coordinates": [
        114.164054,
        22.25959
      ],
      "type": "Point"
    },
    "id": "place.12997319628474470"
  },
  {
    "type": "Feature",
    "properties": {
      "wikidata": "Q3130",
      "place_name": "Australia"
    },
    "geometry": {
      "coordinates": [
        151.21,
        -33.868
      ],
      "type": "Point"
    },
    "id": "place.4960085988742460"
  },
  {
    "type": "Feature",
    "properties": {
      "wikidata": "Q334",
      "place_name": "Singapore"
    },
    "geometry": {
      "coordinates": [
        103.808053,
        1.351616
      ],
      "type": "Point"
    },
    "id": "place.8529875252536600"
  },
  {
    "type": "Feature",
    "properties": {
      "short_code": "FR-75",
      "wikidata": "Q90",
      "place_name": "France"
    },
    "geometry": {
      "coordinates": [
        2.35183,
        48.85658
      ],
      "type": "Point"
    },
    "id": "place.9397217726497330"
  },
  {
    "type": "Feature",
    "properties": {
      "short_code": "US-OR",
      "wikidata": "Q824",
      "place_name": "United States"
    },
    "geometry": {
      "coordinates": [
        -120.575,
        43.93583
      ],
      "type": "Point"
    },
    "id": "region.6684233027164570"
  }
]