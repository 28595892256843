import analyticsExample from "assets/png/analytics-screenshot.png"
import hero from "assets/png/enterprise-hero.png"
import hero2x from "assets/png/enterprise-hero@2x.png"
import hero3x from "assets/png/enterprise-hero@3x.png"
import hero4x from "assets/png/enterprise-hero@4x.png"
import hero5x from "assets/png/enterprise-hero@5x.png"
import multilingualText from "assets/png/multilingual.png"
import chatExample from "assets/png/web-messenger-lead-gen.png"
import adobeLogo from "assets/svg/adobe.svg"
import bubbleChange from "assets/svg/bubble-change.svg"
import bubbleEncryption from "assets/svg/bubble-encryption.svg"
import bubbleGDPR from "assets/svg/bubble-gdpr.svg"
import bubbleReliability from "assets/svg/bubble-reliability.svg"
import bubbleResponse from "assets/svg/bubble-response.svg"
import bubbleScale from "assets/svg/bubble-scale.svg"
import bubbleSLA from "assets/svg/bubble-sla.svg"
import bubbleStorage from "assets/svg/bubble-storage.svg"
import integrations from "assets/svg/company-integrations.svg"
import conversationsChannels from "assets/svg/conversation-channels.svg"
import enterpriseSecurityBg from "assets/svg/enterprise-bg.svg"
import deliveryPartner from "assets/svg/enterprise-delivery-partner.svg"
import enterpriseMessenger from "assets/svg/enterprise-messenger.svg"
import enterpriseQuestionBg from "assets/svg/enterprise-question-bg.svg"
import messageBubbles from "assets/svg/message-bubbles.svg"
import screenshotBg2 from "assets/svg/screenshot-bg-2.svg"
import screenshotBg from "assets/svg/screenshot-bg.svg"
import webhooks from "assets/svg/webhooks.svg"
import BackgroundContainer from "components/bg-container"
import Button from "components/button"
import { A, DividedSection, H1, H2, P } from "components/design-system"
import Image from "components/image"
import InteractiveMap from "components/interactive-map"
import MainLink from "components/main-link"
import Card from "components/new-card"
import Section, { Column } from "components/new-section"
import { studioLinkPrefix } from "config/link"
import { locale } from "config/locale"
import { Link } from "gatsby"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "templates/layout"

export default class Landing extends React.Component {
  render() {
    return (
      <Layout title="Enterprise | Stackchat">

        {/* Hero Section */}
        <BackgroundContainer
          image={[
            `url('${ hero5x }')`,
            `url('${ hero4x }')`,
            `url('${ hero3x }')`,
            `url('${ hero2x }')`,
            `url('${ hero }')`
          ]}
          classes="space-inner-top-10 space-inner-bottom-10"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "bottom 0 left 0"
          }}
        >
          <Section
            partition="full-width"
            classes="home-hero-section space-inner-bottom-10"
          >
            <Column
              className="col-12 col-md-10 space-inner-bottom-10"
              style={{
                backgroundImage: `url("${ messageBubbles }")`,
                backgroundSize: "auto 80px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top 50px right 0"
              }}
            >
              <H2 className="N0 space-outer-top-8">
                <FormattedMessage id="enterprise.section1.heading" />
              </H2>
              <P className="space-outer-top-2 N0">
                <FormattedMessage id="enterprise.section1.description" />
              </P>

              <Link to="/contact">
                <Button
                  title={<FormattedMessage id="enterprise.section1.link" />}
                  className="large space-outer-top-6"
                  background="P500"
                />
              </Link>
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Grow Business */}
        <Section
          partition="60-40"
          classes="space-outer-top-4 space-outer-bottom-8"
          inverse={true}
          leftChild={(
            <BackgroundContainer
              image={`url("${ screenshotBg }")`}
              style={{
                height: "100%",
                backgroundPosition: "50% 50%"
              }}
              classes="d-flex flex-column justify-content-center align-items-center">
              <Image src={chatExample} maxWidth="300px" showShadow={true} classes="space-outer-top-10" />
            </BackgroundContainer>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image=""
              className="d-flex flex-column justify-content-center">
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section2.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section2.description" />
              </P>

              {/* <MainLink text="enterprise.section2.link" link="#" /> */}
            </DividedSection>
          )}
        />

        {/* Global Footprint */}
        <Section
          classes="N10-bg space-inner-top-8 space-inner-bottom-10"
          style={{
            clipPath: "polygon(2px 94.16%, 21% 100%, 43.67% 93.96%, 64.92% 100.2%, 83.50% 93.55%, 100% 100%, 100% 0%, 0% 0%)"
          }}
          partition="60-40"
          leftChild={(
            <InteractiveMap />
          )}
          rightChild={(
            <DividedSection
              image=""
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section3.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section3.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Delivery Section */}
        <Section
          partition="60-40"
          classes="space-inner-top-6 space-inner-bottom-6"
          inverse={true}
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="d-flex justify-content-end"
            >
              <Image src={deliveryPartner} />
            </div>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image=""
              className="d-flex flex-column justify-content-center">
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section4.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section4.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Question */}
        <BackgroundContainer
          image={`url('${ enterpriseQuestionBg }')`}
          classes="space-inner-top-6 space-inner-bottom-6"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50% 50%"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <Column className="col-12 col-md-2 d-flex align-items-start">
                <Image src={enterpriseMessenger} maxWidth="160px" classes="ml-auto mr-auto" />
              </Column>

              <Column className="col-12 col-md-10">
                <H2 className="space-outer-bottom-1 N0 mt-4 mt-sm-0 text-center text-md-left">
                  <FormattedMessage id="enterprise.section5.heading" />
                </H2>

                <P className="N0 text-center text-md-left">
                  <FormattedMessage id="enterprise.section5.description" />
                </P>
              </Column>

              <Column className="col-12 text-center space-outer-top-6">
                <Link to="/contact">
                  <Button
                    title={<FormattedMessage id="enterprise.section5.link" />}
                    className="large"
                  />
                </Link>
              </Column>
            </div>
          </div>
        </BackgroundContainer>


        {/* Security at Stackchat */}
        <Section
          partition="full-width"
          classes="N10-bg space-inner-top-10"
        >
          <Column className="col-12 text-center">
            <H1 className="space-outer-top-0 space-outer-bottom-1">
              <FormattedMessage id="enterprise.section6.heading" />
            </H1>

            <P>
              <FormattedMessage id="enterprise.section6.description" />
            </P>
          </Column>
        </Section>

        <Section
          partition="full-width"
          classes="space-inner-bottom-10 N10-bg"
        >
          <Card
            title="enterprise.section6.card1.title"
            description="enterprise.section6.card1.description"
            image={bubbleGDPR}
          />

          <Card
            title="enterprise.section6.card2.title"
            description="enterprise.section6.card2.description"
            image={bubbleScale}
          />

          <Card
            title="enterprise.section6.card3.title"
            description="enterprise.section6.card3.description"
            image={bubbleEncryption}
          />

          <Card
            title="enterprise.section6.card4.title"
            description="enterprise.section6.card4.description"
            image={bubbleReliability}
          />
        </Section>

        {/* Detailed Analytics */}
        <Section
          partition="50-50"
          classes="space-inner-top-6 space-inner-bottom-6"
          inverse={true}
          leftChild={(
            <BackgroundContainer
              image={`url("${ screenshotBg2 }")`}
              style={{
                height: "100%",
                backgroundPosition: "50% 50%"
              }}
              classes="d-flex flex-column justify-content-center align-items-center">
              <Image src={analyticsExample} showShadow={true} maxWidth="464px" />
            </BackgroundContainer>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image=""
              className="d-flex flex-column justify-content-center">
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section7.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section7.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Omnichannel */}
        <Section
          classes="space-inner-top-10 space-inner-bottom-10 N10-bg"
          style={{
            clipPath: "polygon(0px 100%, 100% 100%, 100% 6%, 71.90% 10.97%, 57.62% 0, 1px 6.6%)"
          }}
          partition="60-40"
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="space-inner-top-6 space-inner-bottom-6"
            >
              <Image src={conversationsChannels} />
            </div>
          )}
          rightChild={(
            <DividedSection
              image=""
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section8.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section8.description" />
              </P>

              {/* <MainLink link="#" text="enterprise.section8.link" /> */}
            </DividedSection>
          )}
        />

        {/* Webhooks */}
        <BackgroundContainer
          image={`url('${ enterpriseSecurityBg }')`}
          classes="space-inner-top-6 space-inner-bottom-6"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50% 50%"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <Column className="col-12 col-md-2 d-flex align-items-start">
                <Image src={webhooks} maxWidth="160px" classes="ml-auto mr-auto" />
              </Column>

              <Column className="col-12 col-md-10 d-flex flex-column justify-content-center">
                <H2 className="space-outer-bottom-1 N0 mt-4 mt-sm-0 text-center text-md-left">
                  <FormattedMessage id="enterprise.section9.heading" />
                </H2>

                <P className="N0 text-center text-md-left">
                  <FormattedMessage id="enterprise.section9.description" />
                </P>

                {/* <MainLink
                  link="/contact"
                  text="enterprise.section9.link"
                  classes="N0 text-center text-md-left"
                /> */}
              </Column>
            </div>
          </div>
        </BackgroundContainer>

        {/* Best Practices */}
        <Section
          partition="full-width"
          classes="space-inner-top-6"
        >
          <Column className="col-12 col-lg-10 offset-lg-1 text-center">
            <H1 className="space-outer-top-0 space-outer-bottom-1">
              <FormattedMessage id="enterprise.section10.heading" />
            </H1>

            <P>
              <FormattedMessage id="enterprise.section10.description" />
            </P>
          </Column>
        </Section>

        <Section
          partition="full-width"
        >
          <Card
            title="enterprise.section10.card1.title"
            description="enterprise.section10.card1.description"
            image={bubbleStorage}
          />

          <Card
            title="enterprise.section10.card2.title"
            description="enterprise.section10.card2.description"
            image={bubbleSLA}
          />

          <Card
            title="enterprise.section10.card3.title"
            description="enterprise.section10.card3.description"
            image={bubbleChange}
          />

          <Card
            title="enterprise.section10.card4.title"
            description="enterprise.section10.card4.description"
            image={bubbleResponse}
          />
        </Section>

        <Section
          partition="full-width"
          classes="space-inner-top-5 space-outer-bottom-6"
        >
          <Column className="col-12 space-outer-bottom-4 text-center">
            <A
              href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
              target="_blank"
              style={{ border: "none" }}
            >
              <Button
                title={<FormattedMessage id="enterprise.section10.link" />}
                className="large space-outer-top-1 space-outer-bottom-1"
              />
            </A>
          </Column>
        </Section>

        {/* Multilingual */}
        <Section
          partition="60-40"
          classes="space-inner-top-10 space-inner-bottom-10 N10-bg"
          inverse={true}
          style={{
            clipPath: "polygon(0px 100%, 100% 100%, 100% 6%, 71.90% 10.97%, 57.62% 0, 1px 6.6%)"
          }}
          leftChild={(
            <Image src={multilingualText} classes="space-outer-top-2" />
          )}
          rightChild={(
            <DividedSection
              left={true}
              image=""
              className="d-flex flex-column justify-content-center">
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section11.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section11.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Integrations */}
        <Section
          classes="space-inner-top-10 space-inner-bottom-10"
          partition="60-40"
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="space-inner-top-6 space-inner-bottom-6"
            >
              <Image src={integrations} />
            </div>
          )}
          rightChild={(
            <DividedSection
              image=""
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section12.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section12.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Adobe Experience Cloud */}
        <BackgroundContainer
          image={`url('${ enterpriseSecurityBg }')`}
          classes="space-inner-top-6 space-inner-bottom-6"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50% 50%"
          }}
        >
          <Section
            partition="full-width"
            classes="text-center"
          >
            <Column className="col-12">
              <H2 className="N0 space-outer-bottom-0">
                <FormattedMessage id="enterprise.section13.heading" />
              </H2>

              <P className="N0">
                <FormattedMessage id="enterprise.section13.description" />
              </P>

              <Image
                src={adobeLogo}
                classes="space-outer-top-4"
                maxWidth="320px"
              />

              {/* <MainLink
                classes="N0"
                text="enterprise.section13.link"
                link="#"
              /> */}
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Ready to Get Started */}
        <Section
          partition="full-width"
          classes="space-inner-top-6 space-inner-bottom-6"
        >
          <Column className="col-12 text-center">
            <H1 className="space-outer-bottom-0">
              <FormattedMessage id="enterprise.section14.heading" />
            </H1>

            <P>
              <FormattedMessage id="enterprise.section14.description" />
            </P>
          </Column>

          <Column
            className="col-12 space-outer-top-3 space-outer-bottom-3"
          >
            <div
              style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
              className="d-flex flex-column flex-sm-row justify-content-between"
            >
              <Link to="/contact">
                <Button
                  title={<FormattedMessage id="enterprise.section14.link1" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                />
              </Link>

              <A href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                target="_blank"
                style={{ border: "none" }}
              >
                <Button
                  title={<FormattedMessage id="enterprise.section14.link2" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                  hollow={true}
                />
              </A>
            </div>
          </Column>
        </Section>
      </Layout>
    )
  }
}